@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
   url("./assets/fonts/Gotham-Light.otf") format("opentype");
  font-weight: normal;
  }
  @font-face {
    font-family: "Gotham-Book";
    src: local("Gotham-Book"),
     url("./assets/fonts/Gotham-Book.otf") format("opentype");
    font-weight: normal;
    }

html {
  box-sizing: border-box;
}
body *, body button {
  cursor: none !important; 
  box-sizing: border-box;
}
*, *:before, *:after{
  box-sizing: inherit;
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Gotham' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f7f8;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: rgb(14, 13, 13);
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  overflow-x: hidden;
  
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}

body::-webkit-scrollbar{
  display: none;
}


